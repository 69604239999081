import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'Shared/Common/debounce';
import SearchInput from 'DesignComponents/Atoms/Input/SearchInput';
import { QuickSearch } from 'Cms/Pages/SearchPage/Search';
import useOutsideClick from 'Shared/Hooks/useOutsideClick';
import ContentContainer from 'DesignComponents/Molecules/ContentContainer/ContentContainer';
import { styled } from 'Theme/stitches.config';
import SearchLinkResultModel from 'Models/Search/SearchLinkResultModel.interface';
import SearchCard from 'Commerce/Molecules/ProductCard/QuickSearchCard';
import { getSearchQueryParameter } from 'Shared/Common/Helpers';
import { canUseDOM } from 'Shared/DOM/WindowHelper';
import Heading from 'DesignSystem/Typography/Headings/Heading';
import LoadingCircle from 'DesignComponents/Atoms/Loaders/LoadingCircle';
import AnimatedIcon from 'DesignSystem/Icons/ExtenderIcons/AnimatedIcon';
import Button from 'Shared/Components/Buttons/Button';
import PrimaryButton from 'DesignComponents/Atoms/Buttons/PrimaryButton';
import InteractableContentLink from 'Shared/Components/Links/InteractableContentLink';
import { SrOnly } from 'DesignSystem/Accessibility/Utils';
import FullSearchResultModel from 'Models/Search/FullSearchResultModel.interface';
import { useFilterData } from 'context/filter.context';
import { NO_OF_MAGAZINES, NO_OF_PAGES, NO_OF_PRODUCTS } from 'Shared/Constants/common';
import { ImageScalingTypes } from 'Shared/Common/ResizeImageEgmontCDN';
import sizes from 'Theme/Settings/sizes';
import { useAppSettings, useTranslations } from '../../../../context/init-data.context';
import { useKexNavigate } from '../../../../lib/router/UseKexNavigate';

type PropTypes = {
  searchIsOpen: boolean;
  setSearchIsOpen: (value: boolean) => void;
  menuRef: React.RefObject<HTMLDivElement>;
};

function DesktopQuickSearch({
  searchIsOpen,
  setSearchIsOpen,
  menuRef,
}: PropTypes) {
  const {
    staticPages: { searchPage },
    languageRoute,
    searchSettings,
  } = useAppSettings();
  const [, dispatchFilterState] = useFilterData();
  const {
    searchLabels: {
      quickSearchCategories,
      quickSearchPages,
      quickSearchProducts,
      showAllResults,
      searchedResult,
      noHits,
      search,
    },
  } = useTranslations();
  const kexNavigate = useKexNavigate();

  const [query, setQuery] = useState<string>('');
  const [result, setResult] = useState<FullSearchResultModel>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resultHits, setResultHits] = useState<number>(0);
  const [magazinesState, setMagazinesState] = useState<
    SearchLinkResultModel[] | undefined
  >();
  const [productsState, setProductsState] = useState<
    SearchLinkResultModel[] | undefined
  >();
  const [pagesState, setPagesState] = useState<
    SearchLinkResultModel[] | undefined
  >();

  const currentUrl = canUseDOM() && window.location.href;

  useOutsideClick(menuRef, () => {
    searchIsOpen && setSearchIsOpen(false);
  });

  const onFocus = () => {
    if (query.length > 1) {
      setSearchIsOpen(true);
    }
  };

  const doSearch = () => {
    if (query.length > 1) {
      setTimeout(() => {
        setSearchIsOpen(false);
      }, 400);

      dispatchFilterState({ type: 'onClear' });
      dispatchFilterState({ type: 'setQuery', value: query });
      kexNavigate(`${searchPage}?query=${query}`);
    }
  };

  const toggleSearch = () => {
    setSearchIsOpen(!searchIsOpen);
  };

  const doQuickSearch = useCallback(async () => {
    const quickSearchQuery = query;
    setIsLoading(true);
    const res = await QuickSearch(
      searchPage,
      quickSearchQuery,
      languageRoute,
      setIsLoading
    );

    // user navigated
    if (getSearchQueryParameter() === query) {
      setSearchIsOpen(false);
      return;
    }

    res && setResult(res);

    setSearchIsOpen(true);
  }, [languageRoute, query, searchPage, setSearchIsOpen]);

  useEffect(() => {
    setTimeout(() => {
      setSearchIsOpen(false);
    }, 500);
  }, [currentUrl]);

  useEffect(() => {
    debounce(() => {
      if (query.length >= 3) {
        doQuickSearch();
      } else {
        setSearchIsOpen(false);
      }
    }, 300);
  }, [query, searchPage, doQuickSearch, setSearchIsOpen]);

  // Calculate QuickSearch result hits
  useEffect(() => {
    if (result) {
      let hits = 0;

      if (result.magazines?.availableItems) {
        hits = result.magazines?.availableItems;
        setMagazinesState(result.magazines.items.slice(0, NO_OF_MAGAZINES));
      } else {
        setMagazinesState(undefined);
      }

      if (result.pages?.availableItems) {
        hits = result.pages.availableItems;
        setPagesState(result.pages.items.slice(0, NO_OF_PAGES));
      } else {
        setPagesState(undefined);
      }

      //Products should only be shown if site is Din Tidning, for Mit blad show subscription offers instead
      if (searchSettings.searchForArticlesOverSubscriptionOffers) {
        if (result.articleProducts?.availableItems) {
          hits = hits + result?.articleProducts?.availableItems;
          setProductsState(
            result.articleProducts?.items.slice(0, NO_OF_PRODUCTS)
          );
        } else {
          setProductsState(undefined);
        }
      } else {
        if (result.subscriptionOffers?.availableItems) {
          hits = hits + result?.subscriptionOffers?.availableItems;
          setProductsState(
            result.subscriptionOffers?.items.slice(0, NO_OF_PRODUCTS)
          );
        } else {
          setProductsState(undefined);
        }
      }

      if (!hits) hits = 0;

      setResultHits(hits);
    }
  }, [result]);

  return (
    <>
      <SearchInput
        doSearch={doSearch}
        query={query}
        setQuery={setQuery}
        placeholder={search}
        onFocus={onFocus}
      />
      {searchIsOpen && (
        <>
          <SrOnly id="quicksearchlabel">{searchedResult}</SrOnly>
          <QuickSearchWrapper
            aria-modal="true"
            role="alertdialog"
            aria-labelledby="quicksearchlabel"
          >
            <ContentContainer css={{ ...containerStyles }}>
              {resultHits ? (
                <Grid>
                  {magazinesState && magazinesState.length > 0 && (
                    <Column>
                      <ColumnHeader>
                        <Heading tag="h2" size="s" id="categoriesSearchHeader">
                          {quickSearchCategories}
                        </Heading>
                      </ColumnHeader>
                      <ColumnContent aria-labelledby="categoriesSearchHeader">
                        {magazinesState.map((item: any, index) => (
                          <ResultListItem key={`${index}`}>
                            <SearchCard
                              item={item}
                              onClick={toggleSearch}
                              imageScalingType={
                                ImageScalingTypes.MINICART_MAGAZINE
                              }
                            ></SearchCard>
                          </ResultListItem>
                        ))}
                      </ColumnContent>
                    </Column>
                  )}
                  {productsState && productsState.length > 0 && (
                    <Column double={productsState.length > 3}>
                      <ColumnHeader>
                        <Heading tag="h2" size="s" id="productsSearchHeader">
                          {quickSearchProducts}
                        </Heading>
                      </ColumnHeader>
                      <ColumnContent aria-labelledby="productsSearchHeader">
                        {productsState?.map(
                          (item: SearchLinkResultModel, index) => {
                            return (
                              <ResultListItem
                                doubleColumns={productsState.length > 3}
                                key={index}
                              >
                                <SearchCard
                                  item={item}
                                  onClick={toggleSearch}
                                  imageScalingType={
                                    ImageScalingTypes.QUICKSEARCH_PRODUCT_CARD
                                  }
                                />
                              </ResultListItem>
                            );
                          }
                        )}
                      </ColumnContent>
                    </Column>
                  )}
                  {pagesState && pagesState.length > 0 && (
                    <Column>
                      <ColumnHeader>
                        <Heading tag="h2" size="s" id="pagesSearchHeader">
                          {quickSearchPages}
                        </Heading>
                      </ColumnHeader>
                      <ColumnContent
                        moreSpace={true}
                        aria-labelledby='pagesSearchHeader"'
                      >
                        {pagesState?.map((item: any, index) => (
                          <ResultListItem key={index}>
                            <InteractableContentLink
                              href={item.url}
                              onClick={toggleSearch}
                            >
                              <PageLink>{item.name}</PageLink>
                            </InteractableContentLink>
                          </ResultListItem>
                        ))}
                      </ColumnContent>
                    </Column>
                  )}
                </Grid>
              ) : (
                <>
                  {isLoading && (
                    <AnimatedIcon animation={isLoading && 'spinAnimation'}>
                      <LoadingCircle />
                    </AnimatedIcon>
                  )}
                </>
              )}
              {typeof resultHits === 'number' && resultHits > 0 && (
                <ButtonWrapper>
                  <Button<typeof PrimaryButton>
                    props={{
                      color: 'Regular',
                      size: 'm',
                      text: showAllResults,
                    }}
                    element="PrimaryButton"
                    onClick={() => {
                      dispatchFilterState({ type: 'setQuery', value: query });
                      kexNavigate(`${searchPage}?query=${query}`);
                    }}
                    isLoading={isLoading}
                  />
                </ButtonWrapper>
              )}
              {typeof resultHits === 'number' &&
                resultHits === 0 &&
                !isLoading && <NoResults>{`${noHits} "${query}"`}</NoResults>}
            </ContentContainer>
          </QuickSearchWrapper>
        </>
      )}
    </>
  );
}

const Grid = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  gap: 30,
});

const Column = styled('div', {
  flexGrow: 1,
  variants: {
    double: {
      true: {
        flexGrow: 2,
      },
    },
  },
});
const ColumnHeader = styled('div', {
  borderStyle: 'none',
  mb: 4,
});

const ColumnContent = styled('ul', {
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  gap: 10,
  height: '100%',
  maxHeight: '400px',
  color: '$onSurface',
  variants: {
    moreSpace: {
      true: {
        gap: 20,
      },
    },
  },
});

const ResultListItem = styled('li', {
  variants: {
    doubleColumns: {
      true: {
        maxWidth: '50%',
      },
    },
  },
});

const PageLink = styled('span', {
  fontFamily: 'fontSemiBold',
});

const ButtonWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  mt: 8,
});
const NoResults = styled('div', {
  fontSize: '$fontSize100',
  fontFamily: 'fontSemiBold',
});

const QuickSearchWrapper = styled('div', {
  py: 8,
  w: '100%',
  t: '$sizes$desktopHeaderUpperHeight',
  l: 0,
  backgroundColor: '$surface',
  position: 'absolute',
  zIndex: '$QuickSearch',
  boxShadow: '0px 4px 6px -4px rgba(145,143,145,1)',
});

const containerStyles = {
  maxH: sizes.mainDesktopMenuMaxHeight,
  overflowY: 'auto',
  minHeight: '100px',
  py: 1,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
};

export default React.memo(DesktopQuickSearch);
