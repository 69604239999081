import { useEffect, useRef, useState } from 'react';
import { styled } from 'Theme/stitches.config';
import { useHeaderData } from '../../Header';
import IHeaderLink from 'Models/Headers/HeaderLinkModel.interface';
import HeaderLinkModel from 'Models/Headers/HeaderLinkModel.interface';
import HeaderLink from './HeaderLink';
import ContentContainer from 'DesignComponents/Molecules/ContentContainer/ContentContainer';
import ItemsNotifier from 'DesignComponents/Atoms/ItemsNotifier/ItemsNotifier';
import { Cart, User } from 'DesignSystem/Icons';
import InteractableContentLink, { TargetTypes } from 'Shared/Components/Links/InteractableContentLink';
import { GetCart } from 'Commerce/Cart/Cart';
import { focusStateRoundedStyle } from 'Shared/Common/focusState';
import sizes from 'Theme/Settings/sizes';
import useOutsideClick from 'Shared/Hooks/useOutsideClick';
import { canUseDOM } from 'Shared/DOM/WindowHelper';
import { animateUnderlineActive, animateUnderlineNotActive } from 'Shared/Common/animateUnderline';
import { useAppSettings, useTranslations } from '../../../../../context/init-data.context';

type PropTypes = {
  menuRef: React.RefObject<HTMLDivElement>;
  openMiniCart: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onOpenMenu: (value: boolean) => void;
};
function HeaderBottom({ menuRef, openMiniCart, onOpenMenu }: PropTypes) {
  const { navigationLinks, myPagesLink } = useHeaderData();
  const navRef = useRef<HTMLUListElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const { languageRoute } = useAppSettings();

  const {
    commonLabels: { openCart },
    checkoutLabels: { items },
  } = useTranslations();

  const { cart } = GetCart(languageRoute);

  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [navigationItems, setNavigationItems] = useState<HeaderLinkModel[]>(
    navigationLinks.links
  );

  const closeMenu = () => {
    setActiveIndex(null);
  };

  useOutsideClick(menuRef, () => {
    closeMenu();
  });

  useEffect(() => {
    setNavigationItems(navigationLinks.links);
  }, [navigationLinks.links]);

  useEffect(() => {
    if (canUseDOM()) {
      const handleKeyDown = (event: KeyboardEvent) => {
        if ((activeIndex === 0 || activeIndex) && event.key === 'Escape') {
          closeMenu();
        }
      };

      window.addEventListener('keyup', handleKeyDown);
      return () => window.removeEventListener('keyup', handleKeyDown);
    }
  }, [activeIndex]);

  useEffect(() => {
    onOpenMenu(activeIndex === 0 || !!activeIndex);
  }, [activeIndex]);

  const handleLinkClick = (index: number) => {
    const updatedItems = navigationItems.map((item) => ({
      ...item,
      active: false,
    }));

    if (index !== activeIndex) {
      updatedItems[index].active = true;
    }

    setNavigationItems(updatedItems);

    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <Container>
      <ContentContainer width={'content'}>
        <HeaderBottomWrapper ref={headerRef}>
          <Left ref={navRef}>
            {navigationItems &&
              navigationItems.map((item: IHeaderLink, index: number) => (
                <li key={index}>
                  <HeaderLink
                    item={item}
                    isActive={index === activeIndex}
                    onClick={() => handleLinkClick(index)}
                    onCloseMenu={closeMenu}
                  />
                </li>
              ))}
          </Left>
          <Right>
            {myPagesLink && (
              <AccountLinkWrapper>
                <InteractableContentLink
                  href={myPagesLink?.href ?? ''}
                  target={(myPagesLink?.target as TargetTypes) ?? ''}
                >
                  <LinkContent>
                    <span>{myPagesLink?.text}</span>
                    <User color="primary" size="m" />
                  </LinkContent>
                </InteractableContentLink>
              </AccountLinkWrapper>
            )}
            <ButtonWrapper
              onClick={openMiniCart}
              aria-label={
                openCart + ' ' + (cart?.numberOfItems ?? 0) + ' ' + items
              }
              aria-live="assertive"
              aria-atomic="true"
            >
              <Cart color="primary" size="m" />
              {cart && <ItemsNotifier items={cart?.numberOfItems} />}
            </ButtonWrapper>
          </Right>
        </HeaderBottomWrapper>
      </ContentContainer>
    </Container>
  );
}

const Container = styled('div', {
  backgroundColor: '$surface',
  mh: 18.5,
  borderBottomWidth: '$borders$default',
  borderColor: '$decorationSubtle',
  borderStyle: 'solid',
  position: 'relative',
});

const AccountButtonStyle = {
  position: 'relative',
  '&:not(:last-child)': {
    mr: 8,
  },
  display: 'flex',
  alignItems: 'center',
  height: '24px',
  cursor: 'pointer',
};

const ButtonWrapper = styled('button', {
  ...AccountButtonStyle,
  ...focusStateRoundedStyle,
});

const AccountLinkWrapper = styled('div', {
  ...AccountButtonStyle,
});

const Left = styled('ul', {
  display: 'flex',
  alignItems: 'start',
  gap: '$s300',
  overflowX: 'auto',
  py: 4,
  pl: 0.5,
  pr: 1,
  maxW: `calc(100% - ${sizes.headerButtonsWidth})`,
  whiteSpace: 'nowrap',
  '@bpMax1280': {
    gap: '$s150',
  },
  backgroundImage: `
    linear-gradient(to right, $surface, $surface),
    linear-gradient(to right, $surface, $surface),
    linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0)),
    linear-gradient(to left, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0))`,
  backgroundPosition: 'left center, right center, left center, right center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '20px 100%, 20px 100%, 10px 100%, 10px 100%',
  backgroundAttachment: 'local, local, scroll, scroll',
});

const Right = styled('div', {
  display: 'flex',
  alignItems: 'end',
  flexShrink: 0,
  py: 4,
});

const HeaderBottomWrapper = styled('nav', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  g: '$s200',
});

const LinkContent = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$s50',
  fontSize: '$fontSize75',
  lineHeight: '$lh24',
  fontFamily: 'fontMedium',
  whiteSpace: 'nowrap',
  color: '$onSurface',
  '& > span': {
    ...animateUnderlineNotActive,
  },
  '&:hover span': {
    ...animateUnderlineActive,
  },
});

export default HeaderBottom;
